
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Gender from "@/components/reusable/Gender.vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import { useRouter } from "vue-router";
import GoBackButton from "@/components/reusable/GoBackButton.vue";

export default defineComponent({
  name: "edit-underwriter-user",
  components: {
    ErrorMessage,
    Field,
    Form,
    Gender,
    GoBackButton,
  },
  data() {
    return {
      publicId: this.$route.params.publicId,
      firstName: "",
      middleName: "",
      lastName: "",
      gender: "",
      emailAddress: "",
      phoneNumber: "",
      avatar: "",
      avatarDisplay: "",
      logo: "",
      logoDisplay: "",
      yearOfEstablishment: "",
      random: "",
      companyName: "",
      currentYear: new Date().getFullYear(),
    };
  },
  setup() {
    const submitButton1 = ref<HTMLElement | null>(null);
    const router = useRouter();

    const underwriterDetailsValidator = Yup.object().shape({
      firstName: Yup.string()
        .required()
        .label("First name"),
      middleName: Yup.string()
        .notRequired()
        .label("First name"),
      lastName: Yup.string()
        .required()
        .label("Last name"),
      emailAddress: Yup.string()
        .required()
        .label("Email address"),
      phoneNumber: Yup.number()
        .required()
        .label("Phone number"),
      yearOfEstablishment: Yup.date()
        .required()
        .label("Year of establishment"),
      gender: Yup.mixed()
        .required()
        .label("Gender"),
    });

    const updateUnderwriter = (values, { resetForm }) => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send underwriter information request
          if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.patch(
              `${variables.SUPERADMIN_UNDERWRITER_ROUTE}/${values["publicId"]}`,
              values,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
              .then((response) => {
                variables.toastAlert(response.data.data.message, "success");
                resetForm();
                router.push({
                  name: "admin-underwriters-users",
                });
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Edit", ["Users", "Underwriters"]);
    });

    return {
      submitButton1,
      updateUnderwriter,
      underwriterDetailsValidator,
    };
  },
  created() {
    this.getUnderwriterInformation();

    //Set page title
    document.title = "Edit Underwriter | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    getUnderwriterInformation() {
      // Get the private motor rate
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.UNDERWRITERS_ROUTE}/${this.publicId}`)
          .then(({ data }) => {
            //Assign data to form fields
            this.companyName = data.data.underwriter.name;
            this.yearOfEstablishment =
              data.data.underwriter.yearOfEstablishment;
            this.logoDisplay = data.data.underwriter.logo;

            this.firstName = data.data.admin ? data.data.admin.firstName : "";
            this.lastName = data.data.admin ? data.data.admin.lastName : "";
            this.middleName = data.data.admin ? data.data.admin.middleName : "";
            this.emailAddress = data.data.admin ? data.data.admin.email : "";
            this.phoneNumber = data.data.admin
              ? data.data.admin.phoneNumber
              : "";
            this.avatarDisplay = data.data.admin
              ? variables.AVATAR_ROUTE + data.data.admin.avatar
              : "";
            this.gender = data.data.admin
              ? data.data.admin.gender.publicId
              : "";
          })
          .catch(function(error) {
            variables.toastAlert(error.message, "error");
          });
      }
    },
    removeImage() {
      this.avatar = "../media/avatars/blank.png";
    },
    acceptNumberOnly(event) {
      variables.acceptNumberOnly(event);
    },
    handleImageUpload(event) {
      const selectedImage = event.target.files[0]; //get the first file

      const reader = new FileReader();
      reader.readAsDataURL(selectedImage);

      reader.onload = (e) => {
        this.avatar = reader.result as string;
      };

      reader.onerror = function(error) {
        variables.toastAlert(error, "error");
      };
    },
    logoImage(event) {
      const selectedImage = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(selectedImage);

      reader.onload = (e) => {
        this.logo = reader.result as string;
      };

      reader.onerror = function(error) {
        variables.toastAlert(error, "error");
      };
    },
  },
});
